import { memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { BoardFiltersButton } from '~/components/Filters/FiltersButton/BoardFiltersButton';
import {
  FiltersDropdownTrigger,
  FiltersDropdownTriggerProps,
} from '~/components/Filters/FiltersButton/FiltersDropdownTrigger';
import {
  PrivateFiltersDropdown,
  PrivateFiltersDropdownProps,
} from '~/components/Filters/FiltersButton/PrivateFiltersDropdown';
import { FilterType, sortFiltersByOrder } from '~/components/FiltersMenu/types';
import { useGetAvailableCustomFields } from '~/hooks/useGetAvailableCustomFields';
import { useURLLibraryIdSelector } from '~/hooks/useURLLibraryIdSelector';
import { usePrivateCustomFieldsContext } from '~/providers/PrivateCustomFieldsProvider';
import { centralizedBoardLibraryIdSelector } from '~/store/centralizedBoard/selectors';
import { isKanbanViewSelector } from '~/store/configViews/selectors';
import { OtherFiltersType } from '~/store/filters/types';
import { getSelectCustomFields } from '~/swr-hooks/customFields/utils';
import { useCanSeePrivateIPTCFields } from '~/swr-hooks/workspaces/addOns/useCanSeePrivateIPTCFields';
import { useCanSeePrivatePeople } from '~/swr-hooks/workspaces/addOns/useCanSeePrivatePeople';
import { useAirSelector } from '~/utils/ReduxUtils';

import { ItemTypeFilter } from '../types';

export interface PrivateFiltersButtonProps extends Pick<PrivateFiltersDropdownProps, 'secondaryCTA'> {
  title: string;
  onApplyClick?: () => void;
  availableTypes?: ItemTypeFilter[];
}

const availableOtherFilters: OtherFiltersType[] = ['has-open-comments', 'has-versions', 'is-untagged'];

export const PrivateBoardFiltersButton = memo(
  ({ title, availableTypes, secondaryCTA, onApplyClick: onApplyFilters }: PrivateFiltersButtonProps) => {
    const isKanbanView = useAirSelector(isKanbanViewSelector);
    const { customFields } = usePrivateCustomFieldsContext();

    const urlLibraryId = useURLLibraryIdSelector();
    const libraryId = useSelector(centralizedBoardLibraryIdSelector);
    const { getAvailableCustomFields } = useGetAvailableCustomFields({
      libraryId: libraryId || urlLibraryId,
    });

    const selectCustomFields = useMemo(() => {
      const availableCustomFields = getAvailableCustomFields(customFields);

      return availableCustomFields ? getSelectCustomFields(availableCustomFields) : [];
    }, [customFields, getAvailableCustomFields]);

    const { canSeePrivateIPTCFields } = useCanSeePrivateIPTCFields();
    const { canSeePrivatePeople } = useCanSeePrivatePeople();

    const availableFilters = useMemo<FilterType[]>(() => {
      const filters: FilterType[] = [
        'type',
        'uploader',
        'color',
        'customField',
        'tags',
        'dateCreated',
        'dateModified',
        'dateUploaded',
        'extension',
        'source',
        'other',
      ];

      if (canSeePrivatePeople) {
        filters.push('people');
      }

      if (canSeePrivateIPTCFields) {
        filters.push(
          'dateTaken',
          'importedKeywords',
          'copyright',
          'creator',
          'camera',
          'audioVisual',
          'videoFrameRate',
          'videoAspectRatio',
          'audioCoding',
          'audioSampleRate',
          'location',
          'city',
          'state',
          'country',
        );
      }

      return filters.sort(sortFiltersByOrder);
    }, [canSeePrivateIPTCFields, canSeePrivatePeople]);

    const renderDropdown: FiltersDropdownTriggerProps['renderFiltersDropdown'] = useCallback(
      ({ closePopover }) => (
        <PrivateFiltersDropdown
          availableFilters={availableFilters}
          otherFilters={availableOtherFilters}
          customFields={selectCustomFields}
          availableTypes={availableTypes}
          secondaryCTA={secondaryCTA}
          onApplyClick={() => {
            closePopover();
            onApplyFilters?.();
          }}
          title={title}
          trackLocation="board"
        />
      ),
      [availableFilters, availableTypes, onApplyFilters, secondaryCTA, selectCustomFields, title],
    );

    if (isKanbanView) return null;

    return <FiltersDropdownTrigger renderFiltersDropdown={renderDropdown} renderTrigger={BoardFiltersButton} />;
  },
);

PrivateBoardFiltersButton.displayName = 'PrivateBoardFiltersButton';
