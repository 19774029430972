import { AirActionTrackingLocation } from '@air/analytics';
import { Board } from '@air/api/types';
import { ChevronRight as ChevronRightIcon } from '@air/next-icons';
import { IconButton } from '@air/primitive-icon-button';
import { useBreakpointsContext } from '@air/provider-media-query';
import React, { CSSProperties, memo, useCallback, useMemo } from 'react';
import isEqual from 'react-fast-compare';

import { GalleryItemType, ItemProps } from '~/components/Gallery/types';
import { useGetPrivateBoardMenuOptionsCallback } from '~/components/PrivateGallery/useGetPrivateBoardMenuOptionsCallback';
import { BoardTableRow, BoardTableRowProps } from '~/components/TableView/BoardTableRow';
import { MobileTableRowContainer } from '~/components/TableView/MobileTableRow/MobileTableRowContainer';
import { PrivateBoardNameCell } from '~/components/TableView/PrivateTableView/PrivateBoardNameCell';
import { PrivateDraggableBoardTableRowContainer } from '~/components/TableView/PrivateTableView/PrivateDraggableBoardTableRowContainer';
import { PrivateBoardCustomFieldTableCell } from '~/components/TableView/TableRow/CustomFieldTableCell/PrivateBoardCustomFieldTableCell';
import { DesktopTableRowContainer } from '~/components/TableView/TableRow/DesktopTableRowContainer';
import { BasicTableRowProps } from '~/components/TableView/types';
import { TABLE_ROW } from '~/constants/testIDs';
import { useGoToBoardPage } from '~/hooks/useGoToBoardPage';
import { useOpenBoardInNewTab } from '~/hooks/useOpenBoardInNewTab';
import { usePrivateSelectionMenuOptions } from '~/hooks/usePrivateSelectionMenuOptions';
import { usePrivateWorkspaceHorizontalPadding } from '~/hooks/usePrivateWorkspaceHorizontalPadding';

export interface PrivateBoardTableRowProps extends BasicTableRowProps<Board> {
  /**
   * `react-virtualized` warns that `style` property should be included in the rendered cell for positioning
   * so we've separated it from the `tx` prop
   * */
  style: CSSProperties;
}

export const PrivateBoardTableRow = memo(({ data: board, style }: PrivateBoardTableRowProps) => {
  const { getSelectionMenuOptions } = usePrivateSelectionMenuOptions();
  const { getBoardMenuOptionsCallback } = useGetPrivateBoardMenuOptionsCallback();
  const { responsiveHorizontalPadding } = usePrivateWorkspaceHorizontalPadding();

  const { isAboveMediumScreen } = useBreakpointsContext();

  const getItemMenuOptions = getBoardMenuOptionsCallback({ item: board });

  const { goToBoardPage } = useGoToBoardPage();
  const { openBoardInNewTab } = useOpenBoardInNewTab();
  const onItemClick = useCallback(
    (trackLocation: AirActionTrackingLocation) => goToBoardPage({ board, trackLocation }),
    [board, goToBoardPage],
  );
  const onItemCmdClick = useCallback(
    (trackLocation: AirActionTrackingLocation) => openBoardInNewTab({ board, trackLocation }),
    [board, openBoardInNewTab],
  );

  const memoizedBoardProps: ItemProps = useMemo(
    () => ({
      isSelectable: true,
      onItemClick,
      onItemCmdClick,
      getSelectionMenuOptions,
      getItemMenuOptions,
    }),
    [getItemMenuOptions, getSelectionMenuOptions, onItemClick, onItemCmdClick],
  );

  const renderNameCell: BoardTableRowProps<Board>['renderNameCell'] = useCallback(
    ({ isSelected, isHovering }) => (
      <PrivateBoardNameCell isHovering={isHovering} isSelected={isSelected} board={board} {...memoizedBoardProps} />
    ),
    [board, memoizedBoardProps],
  );

  return isAboveMediumScreen ? (
    <DesktopTableRowContainer
      style={{
        paddingLeft: responsiveHorizontalPadding,
        paddingRight: responsiveHorizontalPadding,
        ...style,
      }}
      data-testid={`${TABLE_ROW}-${GalleryItemType.board}`}
      data-title={board.title}
    >
      <PrivateDraggableBoardTableRowContainer board={board}>
        <BoardTableRow
          renderNameCell={renderNameCell}
          data={board}
          boardProps={memoizedBoardProps}
          CustomFieldTableCell={PrivateBoardCustomFieldTableCell}
          canSelect={true}
        />
      </PrivateDraggableBoardTableRowContainer>
    </DesktopTableRowContainer>
  ) : (
    <MobileTableRowContainer
      style={{
        paddingLeft: responsiveHorizontalPadding,
        paddingRight: responsiveHorizontalPadding,
        ...style,
      }}
    >
      {renderNameCell({ isSelected: false, isHovering: false })}
      <IconButton
        appearance="ghost"
        color="grey"
        icon={ChevronRightIcon}
        onClick={() => {
          memoizedBoardProps.onItemClick('table-cell');
        }}
        size="extra-large"
        className="h-full"
        label="View board"
      />
    </MobileTableRowContainer>
  );
}, isEqual);

PrivateBoardTableRow.displayName = 'PrivateBoardTableRow';
